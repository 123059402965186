<template>
  <BaseModal
    name="modal-domain"
    id="modal-domain"
    idModal="modal-domain"
    size="lg"
    :title="step1 ? 'Criar domínio' : 'Configurar domínio'"
    @shown="(step1 = true), (dominio = ''), buscaIp()"
  >
    <div v-if="step1 == true">
      <div class="container-table">
        <div class="top-dados mb-5">
          <div>
            <p style="color: var(--white-dark)">
              Selecione o tipo de apontamento e em seguida adicione seu dominio
            </p>
          </div>
        </div>
        <div class="ctn-icons mb-5">
          <div
            class="div-ctn-icons"
            @click="selected_domanin = 'CNAME'"
            :class="{ active: selected_domanin === 'CNAME' }"
          >
            <svg
              :class="{ active_svg: selected_domanin === 'CNAME' }"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#141414"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-globe _svg"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path
                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
              ></path>
            </svg>
            <span :class="{ active_font: selected_domanin === 'CNAME' }"
              >Tipo Cname</span
            >
            <div class="recomendado">
              <img src="@/assets/icons/alert-circle.svg" alt="alert" />
              <span>Recomendado</span>
            </div>
          </div>
          <div
            class="div-ctn-icons"
            @click="selected_domanin = 'A'"
            :class="{ active: selected_domanin === 'A' }"
          >
            <svg
              :class="{ active_svg: selected_domanin === 'A' }"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#141414"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-globe _svg"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path
                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
              ></path>
            </svg>

            <span :class="{ active_font: selected_domanin === 'A' }"
              >Tipo A</span
            >
          </div>
          <div
            class="div-ctn-icons"
            @click="selected_domanin = 'NS'"
            :class="{ active: selected_domanin === 'NS' }"
          >
            <svg
              :class="{ active_svg: selected_domanin === 'NS' }"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#141414"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-globe _svg"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="2" y1="12" x2="22" y2="12"></line>
              <path
                d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
              ></path>
            </svg>

            <span :class="{ active_font: selected_domanin === 'NS' }"
              >Tipo NS</span
            >
          </div>
        </div>
        <div class="dominio" id="dominio">
          <input
            type="text"
            name="protocolo"
            id="protocolo"
            value="https://"
            disabled
            class="input-concat-left"
          />
          <input
            class="w-100 input-concat-right"
            type="text"
            name="dominio"
            id="dominio"
            placeholder="seusite.com.br ou campanha.seusite.com.br"
            v-model="dominio"
          />
        </div>

        <div class="button-right"></div>
      </div>
    </div>
    <div v-else-if="selected_domanin === 'CNAME'">
      <div class="container-table" v-if="selected_domanin === 'CNAME'">
        <p class="_par-domain">
          Siga as orientações abaixo para configurar seu domínio, é preciso
          realizar etapas externas da plataforma {{ nameSystem }}.
        </p>
        <br />
        <p class="_par-domain">Apontamento Tipo Cname</p>
        <span class="spn-domain"
          >O registro Cname é usado para especificar os servidores de nomes para
          um domínio. Para adicionar um registro Cname, siga estas etapas:</span
        >
        <br />
        <br />
        <br />
        <ul class="list-domain">
          <li>1- Faça login na sua conta de hospedagem.</li>
          <li>2- Vá para a seção de gerenciamento de DNS.</li>
          <li>3- Clique em "Adicionar registro".</li>
          <li>4- No campo "Tipo de registro", selecione <b>"Cname"</b>.</li>
          <li>
            5- No campo "Nome", insira o nome de domínio/subdomino que deseja
            mapear para o endereço IP.
          </li>
          <li>
            6- No campo "Endereço Cname", insira o endereço
            <b>external.gdigital.com.br</b>.
          </li>
          <li>7- Clique em "Adicionar".</li>
        </ul>
        <div class="button-right"></div>
      </div>

      <div class="button-right"></div>
    </div>

    <div v-else-if="selected_domanin === 'A'">
      <div class="container-table" v-if="selected_domanin === 'A'">
        <p class="_par-domain">
          Siga as orientações abaixo para configurar seu domínio, é preciso
          realizar etapas externas da plataforma {{ nameSystem }}.
        </p>
        <br />
        <p class="_par-domain">Apontamento Tipo A</p>
        <span class="spn-domain"
          >O registro A é usado para especificar os servidores de nomes para um
          domínio. Para adicionar um registro A, siga estas etapas:</span
        >
        <br />
        <br />
        <br />
        <ul class="list-domain">
          <li>1- Faça login na sua conta de hospedagem.</li>
          <li>2- Vá para a seção de gerenciamento de DNS.</li>
          <li>3- Clique em "Adicionar registro".</li>
          <li>4- No campo "Tipo de registro", selecione <b>"A"</b>.</li>
          <li>5- No campo "Nome", insira o nome de domínio/subdomino que deseja mapear para o endereço IP.</li>
          <li>6- No campo "Endereço IP", insira o endereço <b>IP {{ ip }}</b>.</li>
          <li>7- No campo "TTL", insira o tempo de vida do registro (em segundos).</li>
          <li>8- Clique em "Adicionar".</li>
        </ul>
        <div class="button-right"></div>
      </div>
      <div class="button-right"></div>
    </div>

    <div v-else>
      <div class="container-table" v-if="selected_domanin === 'NS'">
        <p class="_par-domain">
          Siga as orientações abaixo para configurar seu domínio, é preciso
          realizar etapas externas da plataforma {{ nameSystem }}.
        </p>
        <br />
        <p class="_par-domain">Apontamento Tipo NS</p>
        <span class="spn-domain"
          >O registro NS é usado para especificar os servidores de nomes para um
          domínio. Para adicionar um registro NS, siga estas etapas:</span
        >
        <br />
        <br />
        <br />
        <ul class="list-domain">
          <li>1- Faça login na sua conta de hospedagem.</li>
          <li>2- Vá para a seção de gerenciamento de DNS.</li>
          <li>3- Clique em "Adicionar registro".</li>
          <li>4- No campo "Tipo de registro", selecione <b>"NS"</b>.</li>
          <li>5- No campo "Nome", insira o nome de domínio para o qual deseja especificar os servidores de nomes.</li>
          <li>6- No campo "Valor", insira o endereço <b>gdhost.com.br</b>.</li>
          <li>7- No campo "TTL", insira o tempo de vida do registro (em segundos).</li>
          <li>8- Clique em "Adicionar".</li>
        </ul>
        <div class="button-right"></div>
      </div>
    </div>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>

      <BaseButton v-if="step1" @click="criarDominio" variant="primary"
        >Salvar</BaseButton
      >

      <BaseButton v-else @click="closeModal()" variant="primary"
        >Ok, entendi</BaseButton
      >
    </template>
  </BaseModal>
</template>
<script>
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

import BaseButton from "@/components/BaseButton";
import moment from "moment-timezone";

export default {
  name: "ListagemVendas",
  components: {
    BaseButton,
  },
  props: {
    userDomains: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      contracts: [],
      step1: true,
      dominio: "",
      selected_domanin: "CNAME",
      ip: null,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YY HH:ss");
    },
  },
  computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-domain");
      this.$emit("update");
    },
    closeModalInstruction() {
      this.$bvModal.hide("modal-domain-instruction");
      this.$emit("update");
    },
    buscaIp() {
      serviceDomain.read({ id: "get-ip" }).then((ipData) => {
        this.ip = ipData.ip;
      });
    },
    criarDominio() {
      if (!this.dominio) {
        this.$grToast.toast("Preencha o campo com um domínio!", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      if (!this.$store.getters.recursos.hasOwnProperty('ndomain')) {
        return false
      }

      const contractONE = this.contracts.some(contract => contract.produto_id === 136);

      if (this.userDomains >= 1 && contractONE){
        this.$grToast.toast("Para criar novos domínios faça um upgrade no seu plano", {
          title: "Domínios",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      let data = {
        domain: this.dominio,
        type_selected: this.selected_domanin,
      };
      if (this.selected_domanin == "A") {
        data.ip_selected = this.ip;
      }

      const validDominio = /^[á-ùÁ-Úa-zA-Z\d\-.]+$/; 
      if (!validDominio.test(this.dominio)) {
        this.$grToast.toast("Caracteres especiais não são permitidos!", {
          title: "Gestão de sites",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        serviceDomain
          .create(data)
          .then((resp) => {
            if (resp.status === 1) {
              this.$grToast.toast("Domínio já cadastrado!", {
                title: "Domínios",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              return;
            }
            this.step1 = false;
            this.$grToast.toast("Domínio cadastrado com sucesso!", {
              title: "Domínios",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .catch((err) => {
            console.error(err)
            this.$grToast.toast("Erro ao cadastrar Domínio! Entre em contato com o suporte.", {
              title: "Domínios",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      }
    },
  },
  mounted(){
    this.contracts = JSON.parse(sessionStorage.getItem("userContracts")) 
  }
};
</script>

<style lang="scss" scoped>
.list-domain li {
  font-weight: 500;
  font-size: 14px;
  line-height: 17.7px;
  margin-bottom: 10px;
  color: var(--gray01);
}

.spn-domain {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
  color: var(--gray01);
}

._par-domain {
  font-weight: 500;
  font-size: 20px;
  line-height: 24.38px;
  color: var(--gray01);
}

.recomendado {
  height: 22px !important;
  width: 120px !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex !important;
  margin: 0;
  align-items: center;
  justify-content: center;
  background: var(--greenn2);
  color: var(--greenn);
  border-radius: 10px;
}

.recomendado img {
  position: relative;
  width: 15px;
  top: -10px;
  left: -10px;
}

.recomendado span {
  position: relative;
  left: -22px;
  font-size: 10px !important;
  color: var(--greenn) !important;
  font-weight: 600 !important;
}

.ctn-icons img {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
}

.ctn-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 0 auto;
  cursor: pointer;

  .div-ctn-icons {
    width: 180px;
    position: relative;
    height: 120px;
    justify-items: center;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center;

    grid-template-rows: 50px 40px;

    padding-top: 20px;

    margin: 0;
    border-radius: 10px;
    border: 1px solid var(--white-medium);

    &:hover {
      background-color: var(--white-light);
    }

    span {
      font-size: 14px;
      font-weight: 600;
      color: var(--gray01);
    }
  }

  .active {
    border: 2px solid var(--greenn);
  }

  .active_font {
    color: var(--greenn) !important;
  }

  ._svg {
    width: 40px;
    height: 40px;
  }

  .active_svg {
    stroke: var(--greenn);
  }
}

.dominio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 570px;
}

.dominio span {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
}

.button-right {
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  padding: 20px !important;
}

.tab-container {
  padding: 20px;
}

.input-concat-right {
  border-radius: 0px 5px 5px 0px !important;
}

.input-concat-left {
  border-radius: 5px 0px 0px 5px !important;
  width: 120px;
}

.btn-primary {
  border: none;
  background: var(--greenn);
  color: #fff;
  outline: none !important;
  font-weight: 600;
  height: 60px;
  padding: 0 42px !important;
  border-radius: 10px !important;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.07);
  }
}

.btn-primary:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.btn-primary:active {
  background: var(--greenn) !important;
}
</style>
